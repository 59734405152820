var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pa-0 m-background-gradient",
      staticStyle: { "max-width": "100%" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "hidden-sm-and-down" },
            [
              _c("v-img", {
                staticClass: "m-acc-illustration-img",
                attrs: {
                  contain: "",
                  src: require("@af/modules/auth/assets/Login_illustration.png"),
                  position: "top left",
                },
              }),
              _c("div", {
                staticClass:
                  "d-flex flex-column align-center justify-center h-100",
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center align-center h-100" },
            [
              _vm.smallScreen
                ? _c(
                    "v-card",
                    { staticClass: "m-signup pa-4" },
                    [
                      _c("submission-alert-message", {
                        ref: "alertMessage",
                        attrs: { message: `Must validate the fields` },
                        model: {
                          value: _vm.showGeneralMessage,
                          callback: function ($$v) {
                            _vm.showGeneralMessage = $$v
                          },
                          expression: "showGeneralMessage",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c("v-spacer", {
                            staticStyle: { "flex-grow": "0.6 !important" },
                          }),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-4" },
                            [
                              _c(
                                "v-container",
                                {
                                  class: {
                                    "pa-0": _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                },
                                [
                                  _c("v-row", { attrs: { "no-gutters": "" } }, [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-md-center mb-2 m-text-black mk-poppins--text",
                                      },
                                      [_vm._v(" Create Account ")]
                                    ),
                                  ]),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function ($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        {
                                          class: {
                                            "pa-0":
                                              _vm.$vuetify.breakpoint.smAndDown,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c("v-text-field", {
                                                ref: "nameInput",
                                                attrs: {
                                                  "validate-on-blur": "",
                                                  label: "Full Name",
                                                  rules: _vm.rules.name,
                                                  "prepend-icon": "person",
                                                },
                                                model: {
                                                  value: _vm.form_data.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form_data,
                                                      "name",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form_data.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Email",
                                                  autocomplete: "username",
                                                  "validate-on-blur": "",
                                                  "prepend-icon": "email",
                                                  rules: [
                                                    _vm.required,
                                                    _vm.emailValidation,
                                                  ],
                                                },
                                                model: {
                                                  value: _vm.form_data.email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form_data,
                                                      "email",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form_data.email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { sm: "12" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "validate-on-blur": "",
                                                      rules: [
                                                        _vm.passwordValidation,
                                                      ],
                                                      "prepend-icon": "lock",
                                                      "append-outer-icon":
                                                        _vm.show_password
                                                          ? "visibility_off"
                                                          : "visibility",
                                                      type: _vm.show_password
                                                        ? "text"
                                                        : "password",
                                                      autocomplete:
                                                        "new-password",
                                                      name: "password",
                                                      label: "Password",
                                                    },
                                                    on: {
                                                      "click:append-outer":
                                                        function ($event) {
                                                          _vm.show_password =
                                                            !_vm.show_password
                                                        },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form_data.password,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form_data,
                                                          "password",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form_data.password",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Phone Number",
                                                  "validate-on-blur": "",
                                                  rules: [_vm.phoneValidation],
                                                  "prepend-icon": "phone",
                                                },
                                                nativeOn: {
                                                  keypress: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.signup.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.form_data.phone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form_data,
                                                      "phone",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form_data.phone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mk-signup-page-signup-btn",
                                              attrs: {
                                                elevation: "0",
                                                loading:
                                                  _vm.signup_pending.basic,
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.signup(
                                                    $event,
                                                    "basic",
                                                    (_vm.provider = "password")
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Sign Up ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-7",
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mk-signup-with-google d-flex justify-center",
                                              attrs: {
                                                elevation: "0",
                                                loading:
                                                  _vm.signup_pending.google,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.signup(
                                                    $event,
                                                    "basic",
                                                    (_vm.provider = "google")
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "mr-4",
                                                attrs: {
                                                  "max-width": "40px",
                                                  src: require("../assets/social_icons/icon-google.png"),
                                                },
                                              }),
                                              _vm._v(" Continue With Google "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "baseline-content" },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-0 mk-poppins--text",
                                            },
                                            [_vm._v("Have an account!")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-4",
                                          attrs: {
                                            justify: "center",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mk-signup-page-signin-btn",
                                              staticStyle: {
                                                "text-decoration": "none",
                                              },
                                              attrs: {
                                                depressed: "",
                                                to: {
                                                  path: "/auth/signin",
                                                  query: _vm.$route.query,
                                                },
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("Sign in ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("submission-alert-message", {
                        ref: "alertMessage",
                        staticClass: "mb-3",
                        attrs: { message: `Must validate the fields` },
                        model: {
                          value: _vm.showGeneralMessage,
                          callback: function ($$v) {
                            _vm.showGeneralMessage = $$v
                          },
                          expression: "showGeneralMessage",
                        },
                      }),
                      _c("v-card", { staticClass: "m-signup pa-4" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c("v-spacer", {
                              staticStyle: { "flex-grow": "0.6 !important" },
                            }),
                            _c(
                              "v-card-text",
                              { staticClass: "pa-4" },
                              [
                                _c(
                                  "v-container",
                                  {
                                    class: {
                                      "pa-0": _vm.$vuetify.breakpoint.smAndDown,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "text-md-center mb-2 m-text-black mk-poppins--text",
                                          },
                                          [_vm._v(" Create Account ")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-form",
                                      {
                                        ref: "form",
                                        model: {
                                          value: _vm.valid,
                                          callback: function ($$v) {
                                            _vm.valid = $$v
                                          },
                                          expression: "valid",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            class: {
                                              "pa-0":
                                                _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c("v-text-field", {
                                                  ref: "nameInput",
                                                  attrs: {
                                                    "validate-on-blur": "",
                                                    label: "Full Name",
                                                    rules: _vm.rules.name,
                                                    "prepend-icon": "person",
                                                  },
                                                  model: {
                                                    value: _vm.form_data.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form_data,
                                                        "name",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form_data.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Email",
                                                    autocomplete: "username",
                                                    "validate-on-blur": "",
                                                    "prepend-icon": "email",
                                                    rules: [
                                                      _vm.required,
                                                      _vm.emailValidation,
                                                    ],
                                                  },
                                                  model: {
                                                    value: _vm.form_data.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form_data,
                                                        "email",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form_data.email",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { sm: "12" },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "validate-on-blur": "",
                                                        rules: [
                                                          _vm.passwordValidation,
                                                        ],
                                                        "prepend-icon": "lock",
                                                        "append-outer-icon":
                                                          _vm.show_password
                                                            ? "visibility_off"
                                                            : "visibility",
                                                        type: _vm.show_password
                                                          ? "text"
                                                          : "password",
                                                        autocomplete:
                                                          "new-password",
                                                        name: "password",
                                                        label: "Password",
                                                      },
                                                      on: {
                                                        "click:append-outer":
                                                          function ($event) {
                                                            _vm.show_password =
                                                              !_vm.show_password
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form_data
                                                            .password,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form_data,
                                                            "password",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form_data.password",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Phone Number",
                                                    "validate-on-blur": "",
                                                    rules: [
                                                      _vm.phoneValidation,
                                                    ],
                                                    "prepend-icon": "phone",
                                                  },
                                                  nativeOn: {
                                                    keypress: function (
                                                      $event
                                                    ) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.signup.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form_data.phone,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form_data,
                                                        "phone",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form_data.phone",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              "no-gutters": "",
                                              justify: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "mk-signup-page-signup-btn",
                                                attrs: {
                                                  loading:
                                                    _vm.signup_pending.basic,
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.signup(
                                                      $event,
                                                      "basic",
                                                      (_vm.provider =
                                                        "password")
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Sign Up ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mt-7",
                                            attrs: {
                                              "no-gutters": "",
                                              justify: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "mk-signup-with-google d-flex justify-center",
                                                attrs: {
                                                  elevation: "0",
                                                  loading:
                                                    _vm.signup_pending.google,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.signup(
                                                      $event,
                                                      "basic",
                                                      (_vm.provider = "google")
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-img", {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    "max-width": "40px",
                                                    src: require("../assets/social_icons/icon-google.png"),
                                                  },
                                                }),
                                                _vm._v(
                                                  " Continue With Google "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "baseline-content" },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              "no-gutters": "",
                                              justify: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "mb-0 mk-poppins--text",
                                              },
                                              [_vm._v("Have an account!")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mt-4",
                                            attrs: {
                                              justify: "center",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "mk-signup-page-signin-btn",
                                                staticStyle: {
                                                  "text-decoration": "none",
                                                },
                                                attrs: {
                                                  depressed: "",
                                                  to: {
                                                    path: "/auth/signin",
                                                    query: _vm.$route.query,
                                                  },
                                                  color: "primary",
                                                },
                                              },
                                              [_vm._v("Sign in ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-spacer"),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }