export default {
  methods: {
    async validate_google_data() {
      try {
        const resp = (await window.GoogleAuth.signIn()).getAuthResponse();
        return { id_token: resp.id_token };
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    async validate_password_data(form) {
      return form.validate();
    },

    async validate_facebook_data() {
      return new Promise(resolve => {
        window.FB.getLoginStatus(status_response => {
          if (status_response.status === "connected") {
            resolve({ id_token: status_response.authResponse.accessToken });
          } else {
            window.FB.login(
              response => {
                if (response.authResponse)
                  resolve({ id_token: response.authResponse.accessToken });
                else resolve(false);
              },
              { scope: "public_profile,email" }
            );
          }
        });
      });
    }
  }
};
