<template>
  <v-container style="max-width:100%" fluid class="pa-0 m-background-gradient">
    <v-row no-gutters style="height: 100%">
      <v-col class="hidden-sm-and-down">
        <v-img
          contain
          :src="require('@af/modules/auth/assets/Login_illustration.png')"
          position="top left"
          class="m-acc-illustration-img"
        />
        <div class="d-flex flex-column align-center justify-center h-100">
          <!--          <div>-->
          <!--            <v-row justify="center">-->
          <!--              <a :href="$store.state.siteUrl">-->
          <!--                <v-img-->
          <!--                  contain-->
          <!--                  src="../assets/logo.png"-->
          <!--                  class="m-auth__logo"-->
          <!--                  alt="Mouchak Logo"-->
          <!--                />-->
          <!--              </a>-->
          <!--            </v-row>-->
          <!--            <v-row justify="center">-->
          <!--              <h1>Here goes the content</h1>-->
          <!--            </v-row>-->
          <!--          </div>-->
        </div>
      </v-col>
      <v-col class="d-flex justify-center align-center h-100">
        <v-card v-if="smallScreen" class="m-signup pa-4">
          <submission-alert-message
            v-model="showGeneralMessage"
            :message="`Must validate the fields`"
            ref="alertMessage"
          >
          </submission-alert-message>
          <div class="d-flex flex-column">
            <v-spacer style="flex-grow: 0.6 !important"></v-spacer>
            <v-card-text class="pa-4">
              <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
                <v-row no-gutters>
                  <h3 class="text-md-center mb-2 m-text-black mk-poppins--text">
                    Create Account
                  </h3>
                </v-row>
                <v-form v-model="valid" ref="form">
                  <v-container
                    :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-row no-gutters>
                      <v-text-field
                        validate-on-blur
                        label="Full Name"
                        :rules="rules.name"
                        :prepend-icon="'person'"
                        v-model.trim="form_data.name"
                        ref="nameInput"
                      >
                      </v-text-field>
                    </v-row>

                    <v-row no-gutters>
                      <v-text-field
                        label="Email"
                        autocomplete="username"
                        validate-on-blur
                        :prepend-icon="'email'"
                        :rules="[required, emailValidation]"
                        v-model.trim="form_data.email"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row no-gutters>
                      <v-col sm="12" class="pl-0">
                        <v-text-field
                          validate-on-blur
                          v-model.trim="form_data.password"
                          :rules="[passwordValidation]"
                          :prepend-icon="'lock'"
                          :append-outer-icon="
                            show_password ? 'visibility_off' : 'visibility'
                          "
                          :type="show_password ? 'text' : 'password'"
                          autocomplete="new-password"
                          name="password"
                          label="Password"
                          @click:append-outer="show_password = !show_password"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-text-field
                        label="Phone Number"
                        validate-on-blur
                        :rules="[phoneValidation]"
                        :prepend-icon="'phone'"
                        v-model.trim="form_data.phone"
                        @keypress.native.enter="signup"
                      >
                      </v-text-field>
                    </v-row>
                  </v-container>
                  <v-row no-gutters justify="center">
                    <v-btn
                      class="mk-signup-page-signup-btn"
                      elevation="0"
                      @click="signup($event, 'basic', (provider = 'password'))"
                      :loading="signup_pending.basic"
                      color="primary"
                      >Sign Up
                    </v-btn>
                  </v-row>

                  <v-row no-gutters justify="center" class="mt-7">
                    <v-btn
                        elevation="0"
                      class="mk-signup-with-google d-flex justify-center"
                      @click="signup($event, 'basic', (provider = 'google'))"
                      :loading="signup_pending.google"
                    >
                      <v-img
                        class="mr-4"
                        max-width="40px"
                        :src="require('../assets/social_icons/icon-google.png')"
                      />
                      Continue With Google
                    </v-btn>
                    <!-- <v-btn
                      text
                      x-large
                      color="primary"
                      :ripple="false"
                      @click="signup($event, 'basic', (provider = 'facebook'))"
                      :loading="signup_pending.facebook"
                      ><v-img
                        max-width="40px"
                        src="../assets/social_icons/icon-facebook.png"
                      />
                    </v-btn> -->
                  </v-row>
                </v-form>
                <div class="baseline-content">
                  <v-row no-gutters justify="center">
                    <p class="mb-0 mk-poppins--text">Have an account!</p>
                  </v-row>
                  <v-row justify="center" align="center" class="mt-4">
                    <v-btn
                      class="mk-signup-page-signin-btn"
                      depressed
                      :to="{ path: '/auth/signin', query: $route.query }"
                      color="primary"
                      style="text-decoration: none"
                      >Sign in
                    </v-btn>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
            <v-spacer></v-spacer>
          </div>
        </v-card>
        <div v-else>
          <submission-alert-message
            v-model="showGeneralMessage"
            :message="`Must validate the fields`"
            ref="alertMessage"
            class="mb-3"
          >
          </submission-alert-message>
          <v-card class="m-signup pa-4">
            <div class="d-flex flex-column">
              <v-spacer style="flex-grow: 0.6 !important"></v-spacer>
              <v-card-text class="pa-4">
                <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
                  <v-row no-gutters>
                    <h3
                      class="text-md-center mb-2 m-text-black mk-poppins--text"
                    >
                      Create Account
                    </h3>
                  </v-row>
                  <v-form v-model="valid" ref="form">
                    <v-container
                      :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }"
                    >
                      <v-row no-gutters>
                        <v-text-field
                          validate-on-blur
                          label="Full Name"
                          :rules="rules.name"
                          :prepend-icon="'person'"
                          v-model.trim="form_data.name"
                          ref="nameInput"
                        >
                        </v-text-field>
                      </v-row>

                      <v-row no-gutters>
                        <v-text-field
                          label="Email"
                          autocomplete="username"
                          validate-on-blur
                          :prepend-icon="'email'"
                          :rules="[required, emailValidation]"
                          v-model.trim="form_data.email"
                        >
                        </v-text-field>
                      </v-row>
                      <v-row no-gutters>
                        <v-col sm="12" class="pl-0">
                          <v-text-field
                            validate-on-blur
                            v-model.trim="form_data.password"
                            :rules="[passwordValidation]"
                            :prepend-icon="'lock'"
                            :append-outer-icon="
                              show_password ? 'visibility_off' : 'visibility'
                            "
                            :type="show_password ? 'text' : 'password'"
                            autocomplete="new-password"
                            name="password"
                            label="Password"
                            @click:append-outer="show_password = !show_password"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-text-field
                          label="Phone Number"
                          validate-on-blur
                          :rules="[phoneValidation]"
                          :prepend-icon="'phone'"
                          v-model.trim="form_data.phone"
                          @keypress.native.enter="signup"
                        >
                        </v-text-field>
                      </v-row>
                    </v-container>
                    <v-row no-gutters justify="center">
                      <v-btn
                        class="mk-signup-page-signup-btn"
                        @click="
                          signup($event, 'basic', (provider = 'password'))
                        "
                        :loading="signup_pending.basic"
                        color="primary"
                        >Sign Up
                      </v-btn>
                    </v-row>

                    <v-row no-gutters justify="center" class="mt-7">
                      <v-btn
                          elevation="0"
                        class="mk-signup-with-google d-flex justify-center"
                        @click="signup($event, 'basic', (provider = 'google'))"
                        :loading="signup_pending.google"
                      >
                        <v-img
                          class="mr-4"
                          max-width="40px"
                          :src="
                            require('../assets/social_icons/icon-google.png')
                          "
                        />
                        Continue With Google
                      </v-btn>
                      <!-- <v-btn
                        text
                        x-large
                        color="primary"
                        :ripple="false"
                        @click="signup($event, 'basic', (provider = 'facebook'))"
                        :loading="signup_pending.facebook"
                        ><v-img
                          max-width="40px"
                          src="../assets/social_icons/icon-facebook.png"
                        />
                      </v-btn> -->
                    </v-row>
                  </v-form>
                  <div class="baseline-content">
                    <v-row no-gutters justify="center">
                      <p class="mb-0 mk-poppins--text">Have an account!</p>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-4">
                      <v-btn
                        class="mk-signup-page-signin-btn"
                        depressed
                        :to="{ path: '/auth/signin', query: $route.query }"
                        color="primary"
                        style="text-decoration: none"
                        >Sign in
                      </v-btn>
                    </v-row>
                  </div>
                </v-container>
              </v-card-text>
              <v-spacer></v-spacer>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <!-- <v-dialog fullscreen v-model="showRoleChooser" style="overflow: hidden">
      <role-chooser :items="roles" @selected="setRole"></role-chooser>
    </v-dialog> -->
  </v-container>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";
import signin from "@af/modules/auth/mixins/signin";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage.vue";

export default {
  name: "MSignup",
  components: { SubmissionAlertMessage },

  data() {
    return {
      form_data: {
        password: undefined,
        email: undefined,
        name: undefined,
        phone: undefined,
        is_teacher: 0,
        userType: ""
      },
      rules: {
        userType: [v => this.required(v, "User type")],
        name: [
          v => this.required(v, "Name"),
          v => this.minLength(v, 4, "Name"),
          v => this.maxLength(v, 64, "Name")
        ]
      },
      showGeneralMessage: false,
      show_password: false,
      showRoleChooser: true,
      valid: false,
      signup_pending: {
        basic: false,
        google: false,
        facebook: false
      },
      roles: [
        { text: "Signup as Teacher", value: "teacher" },
        { text: "Signup as Student", value: "student" }
      ],
      userTypeList: ["Student", "Teacher"]
    };
  },
  mixins: [FieldValidations, signin],
  computed: {
    formValid() {
      return this.valid && [0, 1].includes(this.form_data.is_teacher);
    },
    smallScreen(){
      return this.$vuetify.breakpoint.width < 600;
    }
  },
  // mounted() {
  //   this.form_data.is_teacher = Number(this.$route.query.isTeacher || 0) || 0;
  // },

  watch: {
    "form_data.userType": {
      handler(newValue) {
        if (newValue == "Student") {
          this.form_data.is_teacher = 0;
        } else if (newValue == "Teacher") {
          this.form_data.is_teacher = 1;
        }
      }
    },
    formValid: {
      handler(value) {
        if (value) {
          this.showGeneralMessage = false;
        }
      }
    }
  },
  methods: {
    async signup($event, type = "basic", provider) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      if (!this.formValid && provider==="password") {
        if (this.showGeneralMessage) {
          this.$refs.alertMessage.flash();
        }
        this.showGeneralMessage = true;
        return;
      }
      try {
        this.signup_pending[type] = true;
        let data = null;
        // eslint-disable-next-line prettier/prettier
        if (
          provider === "password" &&
          this.validate_password_data(this.$refs["form"])
        ) {
          data = this.form_data;
        } else {
          if (this.form_data.is_teacher != undefined) {
            data = await this[`validate_${provider}_data`]();
            data.is_teacher = this.form_data.is_teacher;
          }
        }
        if (data) {
          data.userType = "Student";
          await this.$store.dispatch(this.$store.$types.SIGNUP, {
            data,
            type,
            provider
          });
          await this.$store.dispatch(this.$store.$types.AUTHENTICATE, {
            data,
            type,
            provider
          });
          this.$emit("redirect");
        }
      } catch (error) {
        this.$root.$emit("alert", [
          "Alert",
          error.response ? error.response.data.error : error
        ]);
      } finally {
        this.signup_pending[type] = false;
      }
    }
    /*setRole(roleKey) {
      this.form_data.is_teacher = roleKey === "teacher" ? 1 : 0;
      this.showRoleChooser = false;
      this.$refs.nameInput.focus();
    }*/
  }
};
</script>

<style scoped lang="scss">
.m-signup {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  max-width: 500px;
  width: 500px;
  border-radius: 20px !important;
  min-height: 675px;

  @media (max-width: 600px) {
    min-width: 100vw;
    height: 100vh;
    border-radius: 0px !important;
  }

  .v-card-title {
    padding-bottom: 5px;
  }

  .v-input--checkbox {
    .v-label {
      margin-bottom: 0;
    }
  }
}

.baseline-content {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 70px);
}

.mk-signup-page-signup-btn {
  font-family: "Roboto", "Helvetica", "sans-serif";
  font-weight: normal;
  text-transform: none;
  height: 40px !important;
  width: 100% !important;
  font-size: 16px !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    border: 0.5px solid #0099dc;
    letter-spacing: 0.33px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    border: 1px solid #0099dc;
    letter-spacing: 0.54px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    border: 1px solid #0099dc;
    letter-spacing: 0.54px;
  }
}

.mk-signup-page-signin-btn {
  font-family: "Roboto", "Helvetica", "sans-serif";
  font-weight: normal;
  text-transform: none;
  height: 40px !important;
  width: 160px !important;
  font-size: 16px !important;
  margin-right: 20px;

  // For Small Screen
  @media screen and (max-width: 959px) {
    margin-right: 0px;
  }

  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0.33px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    letter-spacing: 0.54px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    letter-spacing: 0.54px;
  }
}

.mk-signup-with-google {
  font-family: "Roboto", "Helvetica", "sans-serif";
  font-weight: normal;
  text-transform: none;
  height: 45px !important;
  width: 100%;
  font-size: 16px !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0.33px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    letter-spacing: 0.54px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    letter-spacing: 0.54px;
  }
}

.mk-signup-with-google .v-btn__content,
.mk-signup-page-signin-btn .v-btn__content,
.mk-signup-page-signup-btn .v-btn__content {
  padding-top: 4px;
}
</style>
